[data-v-489fbc48] .el-dialog {
  max-height: calc(100% - 20px);
  background: #f9fafc !important;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 32px 60px;
  border-radius: 10px;
  display: block;
}
[data-v-489fbc48] .el-dialog .el-dialog__header {
    line-height: 34px;
    -webkit-box-shadow: rgba(12, 31, 80, 0.08) 0px 4px 10px 0px;
            box-shadow: rgba(12, 31, 80, 0.08) 0px 4px 10px 0px;
    background: #fff !important;
    border-radius: 20px 24px 10px 10px;
    overflow: hidden;
}
[data-v-489fbc48] .el-dialog .el-dialog__body {
    -webkit-box-shadow: rgba(12, 31, 80, 0.08) 0px 4px 10px 0px;
            box-shadow: rgba(12, 31, 80, 0.08) 0px 4px 10px 0px;
    margin-top: 10px;
    border-radius: 10px;
    padding: 10px !important;
    height: calc(100% - 140px);
}
[data-v-489fbc48] .el-dialog .el-dialog__footer {
    text-align: center;
}
[data-v-489fbc48] .el-dialog .el-dialog__headerbtn .el-dialog__close {
    color: #000;
}
.my-viewer-title-img[data-v-489fbc48] {
  width: 100%;
  height: 20px;
  background-image: url("https://file.qingflow.com/assets/widget/theme/header0.png");
  background-size: cover;
}
.my-viewer-title[data-v-489fbc48] {
  color: #000;
  width: 100%;
  padding: 16px;
  height: 34px;
}
.my-viewer-title span[data-v-489fbc48] {
    font-family: BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", Helvetica, Arial, "PingFang SC", "Microsoft Yahei UI", "Microsoft Yahei", sans-serif font-feature-settings "tnum", "tnum";
    font-size: 20px;
    font-weight: 600;
}
